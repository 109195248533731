import { rootApi as api } from "./root_api";
export const addTagTypes = [
  "event",
  "reimbursement",
  "member",
  "content",
  "pay",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postEvent: build.mutation<PostEventApiResponse, PostEventApiArg>({
        query: (queryArg) => ({
          url: `/event`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["event"],
      }),
      getEvent: build.query<GetEventApiResponse, GetEventApiArg>({
        query: (queryArg) => ({ url: `/event/${queryArg.eventId}` }),
        providesTags: ["event"],
      }),
      getReimbursements: build.query<
        GetReimbursementsApiResponse,
        GetReimbursementsApiArg
      >({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/reimbursement`,
        }),
        providesTags: ["reimbursement"],
      }),
      postReimbursement: build.mutation<
        PostReimbursementApiResponse,
        PostReimbursementApiArg
      >({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/reimbursement`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["reimbursement"],
      }),
      putReimbursement: build.mutation<
        PutReimbursementApiResponse,
        PutReimbursementApiArg
      >({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/reimbursement`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["reimbursement"],
      }),
      deleteReimbursement: build.mutation<
        DeleteReimbursementApiResponse,
        DeleteReimbursementApiArg
      >({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/reimbursement`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["reimbursement"],
      }),
      getMembersByEventId: build.query<
        GetMembersByEventIdApiResponse,
        GetMembersByEventIdApiArg
      >({
        query: (queryArg) => ({ url: `/event/${queryArg.eventId}/member` }),
        providesTags: ["member"],
      }),
      postMember: build.mutation<PostMemberApiResponse, PostMemberApiArg>({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/member`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["member"],
      }),
      getContentsByEventId: build.query<
        GetContentsByEventIdApiResponse,
        GetContentsByEventIdApiArg
      >({
        query: (queryArg) => ({ url: `/event/${queryArg.eventId}/content` }),
        providesTags: ["content"],
      }),
      postContent: build.mutation<PostContentApiResponse, PostContentApiArg>({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/content`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["content"],
      }),
      putContent: build.mutation<PutContentApiResponse, PutContentApiArg>({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/content`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["content"],
      }),
      getPaysByEventId: build.query<
        GetPaysByEventIdApiResponse,
        GetPaysByEventIdApiArg
      >({
        query: (queryArg) => ({ url: `/event/${queryArg.eventId}/pay` }),
        providesTags: ["pay"],
      }),
      upsertPay: build.mutation<UpsertPayApiResponse, UpsertPayApiArg>({
        query: (queryArg) => ({
          url: `/event/${queryArg.eventId}/pay`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["pay"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as waricanApi };
export type PostEventApiResponse = /** status 200 Successful operation */ {
  event_id: string;
  created_at: string;
  updated_at: string;
};
export type PostEventApiArg = {
  /** Insert a new reimbursement. */
  body: {
    user_id: string;
    title: string;
  };
};
export type GetEventApiResponse = /** status 200 Successful operation */ {
  title: string;
  event_id: string;
  created_at: string;
  updated_at: string;
};
export type GetEventApiArg = {
  /** event id. */
  eventId: string;
};
export type GetReimbursementsApiResponse =
  /** status 200 Successful operation */ Reimbursements;
export type GetReimbursementsApiArg = {
  /** ID of user to return */
  eventId: string;
};
export type PostReimbursementApiResponse =
  /** status 201 Successful operation */ Blob;
export type PostReimbursementApiArg = {
  /** event id. */
  eventId: string;
  /** Insert a new reimbursement. */
  body: {
    amount: number;
    currency: string;
    content_id?: string;
    reimbursement_member: string;
    create_user: string;
  };
};
export type PutReimbursementApiResponse =
  /** status 200 Successful operation */ Blob;
export type PutReimbursementApiArg = {
  /** event id. */
  eventId: string;
  /** Amend a reimbursement. */
  body: {
    reimbursement_id: string;
    amount: number;
    currency: string;
    content_id?: string;
    reimbursement_member: string;
    create_user: string;
  };
};
export type DeleteReimbursementApiResponse =
  /** status 200 Successful operation */ Blob;
export type DeleteReimbursementApiArg = {
  /** event id. */
  eventId: string;
  /** Amend a reimbursement. */
  body: {
    reimbursement_id: string;
  };
};
export type GetMembersByEventIdApiResponse =
  /** status 200 Successful operation */ Members;
export type GetMembersByEventIdApiArg = {
  /** ID of event. */
  eventId: string;
};
export type PostMemberApiResponse = /** status 200 Successful operation */ {
  member_name: string;
  member_id: string;
};
export type PostMemberApiArg = {
  /** iD of event. */
  eventId: string;
  /** Insert a new reimbursement. */
  body: {
    name: string;
    create_user: string;
  };
};
export type GetContentsByEventIdApiResponse =
  /** status 200 Successful operation */ Contents;
export type GetContentsByEventIdApiArg = {
  /** ID of event. */
  eventId: string;
};
export type PostContentApiResponse = /** status 200 Successful operation */ {
  name?: string;
  content_id: string;
};
export type PostContentApiArg = {
  /** iD of event. */
  eventId: string;
  /** Insert a new content. */
  body: {
    name: string;
    create_user: string;
  };
};
export type PutContentApiResponse = /** status 200 Successful operation */ {
  content_name: string;
  content_id: string;
};
export type PutContentApiArg = {
  /** iD of event. */
  eventId: string;
  /** Insert a new content. */
  body: {
    content_id: string;
    name: string;
    create_user: string;
  };
};
export type GetPaysByEventIdApiResponse =
  /** status 200 Successful operation */ Pays;
export type GetPaysByEventIdApiArg = {
  /** ID of event. */
  eventId: string;
};
export type UpsertPayApiResponse = /** status 200 Successful operation */ Blob;
export type UpsertPayApiArg = {
  /** iD of event. */
  eventId: string;
  /** upsert a new content. */
  body: {
    content_id: string;
    member_id: string;
    pay: boolean;
  };
};
export type Reimbursement = {
  reimbursement_id: string;
  reimbursement_member: string;
  content_id: string;
  amount: number;
  currency: string;
  create_user: string;
};
export type Reimbursements = Reimbursement[];
export type Member = {
  member_id: string;
  name: string;
  create_user: string;
};
export type Members = Member[];
export type Content = {
  content_id: string;
  name: string;
  create_user: string;
};
export type Contents = Content[];
export type Pay = {
  content_id: string;
  member_id: string;
  pay: boolean;
};
export type Pays = Pay[];
export const {
  usePostEventMutation,
  useGetEventQuery,
  useGetReimbursementsQuery,
  usePostReimbursementMutation,
  usePutReimbursementMutation,
  useDeleteReimbursementMutation,
  useGetMembersByEventIdQuery,
  usePostMemberMutation,
  useGetContentsByEventIdQuery,
  usePostContentMutation,
  usePutContentMutation,
  useGetPaysByEventIdQuery,
  useUpsertPayMutation,
} = injectedRtkApi;
