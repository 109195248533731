import { Head } from "src/component/atoms/head/head";
import { Header } from "src/component/organisms/header/header";

export const NotFoundPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Head level={1}>Not Found</Head>
      <div>お探しのページは見つかりませんでした。</div>
    </div>
  );
};
