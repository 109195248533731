import { SxProps, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

export type WaricanAutoCompleteProps = {
  suggestions: CompleteItem[];
  sx?: SxProps;
  placeholder?: string;
  title: string;
  /**
   * この実装はCompleteItemの変更に対して頑健である代わりに、変更差分を動的に取得できません。
   */
  onChange?: (id: string, displayName: string) => void;
  /**
   * この実装はCompleteItemの変更に対して頑健である代わりに、変更差分を動的に取得できません。
   */
  onCreateNewItem?: (displayName: string) => void;
  initialValue?: CompleteItem;
};
export type CompleteItem = {
  displayName: string;
  id: string;
  /**
   * The value is true when the user enters a new value.
   */
  isNewItem?: boolean;
};
const filter = createFilterOptions<CompleteItem>();

/**
 * @see https://mui.com/material-ui/react-autocomplete/#creatable
 */
export const WaricanAutoComplete = (props: WaricanAutoCompleteProps) => {
  const [value, setValue] = useState<CompleteItem>(
    props.initialValue ?? { displayName: "", id: "" }
  );

  useEffect(() => {
    if (props.initialValue) {
      setValue(props.initialValue);
    }
  }, [props.initialValue]);

  return (
    <Autocomplete
      sx={props.sx}
      value={value}
      onChange={(event, newValue) => {
        console.info("onchange", newValue);
        if (newValue?.isNewItem) {
          // Create a new value from the user input
          setValue({
            displayName: newValue.id,
            id: newValue.id,
          });
          if (props.onCreateNewItem) {
            // id is "XXX", displayName is "Add: XXX", so use id as displayName.
            props.onCreateNewItem(newValue.id);
          }
          if (props.onChange) {
            props.onChange(newValue.id, newValue.displayName);
          }
        } else if (newValue) {
          setValue({
            displayName: newValue.displayName,
            id: newValue.id,
          });
          if (props.onChange) {
            props.onChange(newValue.id, newValue.displayName);
          }
        } else {
          console.warn("newValue is undefined!!!");
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            isNewItem: true,
            id: params.inputValue,
            displayName: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={props.suggestions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.isNewItem) {
          return option.id;
        }
        // Regular option
        return option.displayName;
      }}
      renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.title}
          placeholder={props.placeholder}
        />
      )}
    />
  );
};
