import { Input, SxProps } from "@mui/material";
import { useCallback } from "react";

export type WaricanInputNumberProps = {
  value: number;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  sx?: SxProps;
  placeholder?: string;
};

/**
 * propsをtype="number"とtypo="text"で調整することが難しいので、別コンポーネントとして作成しています。
 * @param props
 * @returns
 */
export const WariCanInputNumber = (props: WaricanInputNumberProps) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
    },
    [props]
  );
  return (
    <Input
      placeholder={props.placeholder}
      type={"number"}
      value={props.value ?? ""}
      onChange={onChange}
      sx={props.sx}
      inputProps={{
        min: 0,
        max: 1000000,
        style: { textAlign: "right" },
      }}
    />
  );
};
