import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WaricanEvent } from "src/model/event";
import { User } from "src/model/user";
import { saveCookie } from "src/storage/cookie";
import { prepareUserData, saveUserData } from "src/util/user";

type GridState = {
  contents: {
    [memberId: string]: {
      pay: {
        [contentId: string]: {
          /**
           * true: 支払う
           * false: 支払わない
           */
          pay: boolean;
        };
      };
      totalAmount: number;
      /**
       * 本質的にはpayを足せばいいですが。簡単のためにここにも持たせます。
       */
      personsToPay: number;
    };
  };
};

const initialState: GridState = {
  contents: {},
};

export const gridSlice = createSlice({
  name: "grid",
  initialState: initialState,
  reducers: {
    changePayment: (
      state,
      action: PayloadAction<{
        memberId: string;
        contentId: string;
        pay: boolean;
      }>
    ) => {
      if (state.contents[action.payload.memberId]) {
        
       
      }
    },
    setContent: (state, action: PayloadAction<GridState["contents"]>) => {
      state.contents = action.payload;
    },
  },
});

export const { setContent, changePayment } = gridSlice.actions;
