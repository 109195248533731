import ShareIcon from "@mui/icons-material/Share";
import { WaricanIconProps } from "src/component/atoms/icons/icon_core";

/**
 * DeleteIcon.
 * @returns DeleteIcon.
 * @example
 * <DeleteIcon />
 * @todo
 * - [ ] DeleteIcon.
 */
export const WaricanShareIcon = (props: WaricanIconProps) => {
  return (
    <ShareIcon color={props.color} onClick={props.onClick} cursor="pointer" />
  );
};
