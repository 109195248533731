import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { WaricanButton } from "src/component/atoms/button/button";
import { WaricanDeleteIcon } from "src/component/atoms/icons/delete_icon/delete_icon";
import { WariCanInputNumber } from "src/component/atoms/input/input_number";
import { WaricanText } from "src/component/atoms/text/text";
import { ContentAutoComplete } from "src/component/organisms/content_auto_complete/content_auto_complete";
import { MemberAutoComplete } from "src/component/organisms/member_auto_complete/member_auto_complete";
import { useModalContext } from "src/component/organisms/modal_context/modal_context";
import { useRootSelector } from "src/store/root_store";
import { isSuccessResponse } from "src/store/util";
import {
  useDeleteReimbursementMutation,
  usePostReimbursementMutation,
  usePutReimbursementMutation,
} from "src/store/waricanApi";
import {
  closeSnackbarAction,
  defaultSnackbarOptions,
} from "src/util/notification";

export type ReimbursementFormProps = {
  eventId: string;
  name?: string;
  amount?: number;
  currency?: string;
  content_id?: string;
  reimbursement_member?: string;
  /**
   * when reimbursementID is None, this component is new
   */
  reimbursementID?: string;
};

export const ReimbursementForm = (props: ReimbursementFormProps) => {
  const { enqueueSnackbar: popUpSnackbar } = useSnackbar();
  const { open, setIsOpen } = useModalContext();
  const user = useRootSelector((state) => state.user.user);

  const [postReimbursement] = usePostReimbursementMutation();
  const [putReimbursement] = usePutReimbursementMutation();

  const [reimbursementAmount, setReimbursementAmount] = useState<number>(
    props.amount ?? 0
  );

  useEffect(() => {
    if (props.amount !== undefined) {
      setReimbursementAmount(props.amount);
    }
  }, [props.amount]);

  const [memberFrom, setMemberFrom] = useState<string>(
    props.reimbursement_member ?? ""
  );

  useEffect(() => {
    if (props.reimbursement_member !== undefined) {
      setMemberFrom(props.reimbursement_member);
    }
  }, [props.reimbursement_member]);

  const [content, setContent] = useState<string>(props.content_id ?? "");

  useEffect(() => {
    if (props.content_id !== undefined) {
      setContent(props.content_id);
    }
  }, [props.content_id]);

  const isNew = useMemo(() => {
    return props.reimbursementID === undefined;
  }, [props.reimbursementID]);

  const buttonMessage = useMemo(() => {
    return isNew ? "追加" : "修正";
  }, [isNew]);

  const onReimbursementAmountChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // TODO: この実装は小数点以下を許可しないため、拡張性が下がる可能性がありますが、文字列パースを簡略化するため一旦この実装を許容しています。
      setReimbursementAmount(parseInt(e.target.value));
    },
    []
  );

  const canAdd = useMemo(() => {
    return memberFrom && content && reimbursementAmount;
  }, [content, memberFrom, reimbursementAmount]);

  const onButtonClick = useCallback(() => {
    if (isNew) {
      postReimbursement({
        eventId: props.eventId,
        body: {
          amount: reimbursementAmount,
          create_user: user.userId,
          reimbursement_member: memberFrom,
          content_id: content,
          currency: "JPY",
        },
      }).then((resp) => {
        if (isSuccessResponse(resp)) {
          setReimbursementAmount(0);
          setMemberFrom("");
          setContent("");
          popUpSnackbar("立て替えを追加しました", {
            variant: "success",
            ...defaultSnackbarOptions,
          });
        }
      });
    } else {
      if (!props.reimbursementID) return;
      putReimbursement({
        eventId: props.eventId,
        body: {
          reimbursement_id: props.reimbursementID,
          amount: reimbursementAmount,
          create_user: user.userId,
          reimbursement_member: memberFrom,
          content_id: content,
          currency: "JPY",
        },
      }).then(() => {
        popUpSnackbar("立て替え内容を更新しました。", {
          variant: "success",
          autoHideDuration: 2000,
          preventDuplicate: true,
          action: closeSnackbarAction,
        });
      });
    }
  }, [
    content,
    isNew,
    memberFrom,
    popUpSnackbar,
    postReimbursement,
    props.eventId,
    props.reimbursementID,
    putReimbursement,
    reimbursementAmount,
    user.userId,
  ]);

  // const [memberFromName, setMemberFromName] = useState<string>("");

  const [deleteReimbursement] = useDeleteReimbursementMutation();
  const onDeleteClick = useCallback(() => {
    deleteReimbursement({
      eventId: props.eventId,
      body: { reimbursement_id: props.reimbursementID ?? "" },
    }).then((resp) => {
      popUpSnackbar("立て替えを削除しました", {
        variant: "success",
        ...defaultSnackbarOptions,
      });
      setIsOpen(false);
    });
  }, [
    deleteReimbursement,
    popUpSnackbar,
    props.eventId,
    props.reimbursementID,
    setIsOpen,
  ]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      {isNew ? (
        <></>
      ) : (
        <WaricanDeleteIcon
          color="warning"
          onClick={() => {
            open(
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                削除しますか？
                <div
                  style={{
                    marginRight: "0",
                    marginLeft: "auto",
                    width: "auto",
                  }}
                >
                  <WaricanButton message="はい" onClick={onDeleteClick} />
                </div>
              </Box>
            );
          }}
        />
      )}
      <MemberAutoComplete
        onChange={setMemberFrom}
        placeholder="from"
        eventId={props.eventId}
        title="from"
        defaultMemberId={props.reimbursement_member}
      />
      <WaricanText textJp="が" />
      <ContentAutoComplete
        onChange={setContent}
        placeholder="飲み代"
        eventId={props.eventId}
        title="項目"
        defaultContentId={props.content_id}
      />
      <WaricanText textJp="で" />
      <WariCanInputNumber
        sx={{ flexGrow: 1 }}
        value={reimbursementAmount}
        onChange={onReimbursementAmountChange}
      />
      <WaricanText textJp="円立て替え" />
      <WaricanButton
        message={buttonMessage}
        onClick={onButtonClick}
        disabled={!canAdd}
      />
    </Box>
  );
};
