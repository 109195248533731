import { CSSProperties, ReactNode, useMemo } from "react";
import Typography from "@mui/material/Typography";

export type HeadProps = {
  level: number;
  children: ReactNode;
  style?: CSSProperties;
};

export const Head = (props: HeadProps) => {
  const variant = useMemo<any>(() => {
    return `h${props.level}`;
  }, [props.level]);

  const style = useMemo<CSSProperties>(() => {
    return generateHeadStyle(props.level);
  }, [props.level]);

  return (
    <Typography
      style={{ ...style, ...props.style }}
      variant={variant}
      color={props.level === 1 ? "" : "primary"}
    >
      {props.children}
    </Typography>
  );
};
const generateHeadStyle = (level: number): CSSProperties => {
  switch (level) {
    case 1:
      return {
        fontSize: "2.0rem",
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
      };
    case 2:
      return {
        fontSize: "1.7rem",
        marginTop: "1.0rem",
        marginBottom: "1.0rem",
      };
    case 3:
      return {
        fontSize: "1.4rem",
        marginTop: "0.7rem",
        marginBottom: "0.7rem",
      };
    case 4:
      return {
        fontSize: "1rem",
      };
    case 5:
      return {
        fontSize: "0.83rem",
      };
    default:
      return {};
  }
};
