import { Button } from "@mui/material";

export type WaricanButtonProps = {
  message: string;
  onClick?: () => void;
  disabled?: boolean;
};
export const WaricanButton = (props: WaricanButtonProps) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.message}
    </Button>
  );
};
