import { ThemeProvider } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ResponsiveFrame } from "src/component/core/responsive";
import { ModalWrapper } from "src/component/organisms/modal_context/modal_context";
import "src/index.css";
import { rootStore } from "src/store/root_store";
import { myTheme } from "src/style/mui_theme";
import reportWebVitals from "./reportWebVitals";
import App from "./route";
import "src/index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={5}>
      <ThemeProvider theme={myTheme}>
        <Provider store={rootStore}>
          <ModalWrapper>
            <BrowserRouter>
              <ResponsiveFrame>
                <App />
              </ResponsiveFrame>
            </BrowserRouter>
          </ModalWrapper>
        </Provider>
      </ThemeProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
