import { ColDef, ColGroupDef } from "ag-grid-community";
import { EnqueueSnackbar, useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { WaricanGrid } from "src/component/molecules/warican_grid/warican_grid";
import { WaricanContent } from "src/model/content";
import { WaricanPay } from "src/model/pay";
import { WaricanReimbursement } from "src/model/reimbursement";
import {
  useGetContentsByEventIdQuery,
  useGetMembersByEventIdQuery,
  useGetPaysByEventIdQuery,
  useGetReimbursementsQuery,
  useUpsertPayMutation,
} from "src/store/waricanApi";
import { closeSnackbarAction } from "src/util/notification";

export type ReimBursementGridProps = {
  eventId: string;
};

/**
 * change content id field to whether_to_pay field name.
 * @param contentName
 * @returns
 */
const changeToPayBoolField = (contentName: string) => {
  return contentName + "_whether_to_pay";
};

const generatePayColumnDef = (
  content: WaricanContent,
  eventId: string,
  upsertPay: any,
  contentsPayMember: { [contentId: string]: number },
  popUpSnackbar: EnqueueSnackbar
): ColDef => {
  return {
    field: content.content_id,
    headerName: content.name,
    // naive calculation.
    width: content.name.length * 10 + 60,
    cellRenderer: "agAnimateShowChangeCellRenderer",
    valueGetter: (params) => {
      if (params.colDef.field) {
        const targetPayBoolField = changeToPayBoolField(params.colDef.field);
        // 1かtrueが来ることを想定しています。危険な分岐です
        if (params.data[targetPayBoolField]) {
          return Math.round(params.data[params.colDef.field]);
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    onCellClicked: (params) => {
      if (params.colDef.field) {
        // 値が未定義の場合
        if (
          params.data[changeToPayBoolField(params.colDef.field)] === undefined
        ) {
          console.debug("undefined", {
            ...params.data,
            [changeToPayBoolField(params.colDef.field)]: true,
          });
          upsertPay({
            body: {
              content_id: params.colDef.field,
              member_id: params.data["memberId"],
              pay: true,
            },
            eventId: eventId,
          });
        }
        // 支払わない場合
        else if (params.data[changeToPayBoolField(params.colDef.field)]) {
          // 1人も支払わなくなる場合
          if (contentsPayMember[params.colDef.field] === 1) {
            popUpSnackbar("1人も支払わないことはできません。", {
              variant: "error",
              autoHideDuration: 5000,
              preventDuplicate: true,
              action: closeSnackbarAction,
            });
            return;
          }

          upsertPay({
            body: {
              content_id: params.colDef.field,
              member_id: params.data["memberId"],
              pay: false,
            },
            eventId: eventId,
          });
        }
        // 支払う場合
        else {
          upsertPay({
            body: {
              content_id: params.colDef.field,
              member_id: params.data["memberId"],
              pay: true,
            },
            eventId: eventId,
          });
        }
      }
    },
  };
};

/**
 * generate columnDefs for ag-grid.
 * @params contents
 * @params upsertPay - please see useUpsertPayMutation.
 * @returns
 */
const generateColumnDefs = (
  contents: WaricanContent[],
  eventId: string,
  upsertPay: any,
  contentsPayMember: { [contentId: string]: number },
  contentsAmount: { [contentId: string]: number },
  popUpSnackbar: EnqueueSnackbar
): (ColDef | ColGroupDef)[] => {
  const contentsHasReimbursement = contents.filter((content) => {
    return contentsAmount[content.content_id] !== undefined;
  });
  return [
    { field: "memberName", headerName: "メンバー", width: 100, pinned: "left" },
    generateTotalColumnDef(contents),
    ...contentsHasReimbursement.map((content) => {
      return generatePayColumnDef(
        content,
        eventId,
        upsertPay,
        contentsPayMember,
        popUpSnackbar
      );
    }),
  ];
};

const generateTotalColumnDef = (contents: WaricanContent[]): ColDef => {
  return {
    headerName: "支払い総額",
    valueGetter: (params) => {
      console.debug(params.data);
      const total = contents.reduce((prev, content) => {
        if (params.data[content.content_id]) {
          return prev + params.data[content.content_id];
        } else {
          return prev;
        }
      }, 0);
      return Math.round(total);
    },
    width: 120,
  };
};

const aggregateReimbursementData = (
  data: WaricanReimbursement[]
): { [contentId: string]: number } => {
  const contentTotalReimbursements: { [contentId: string]: number } = {};
  data.forEach((reimbursement) => {
    if (contentTotalReimbursements[reimbursement.content_id]) {
      contentTotalReimbursements[reimbursement.content_id] +=
        reimbursement.amount;
    } else {
      contentTotalReimbursements[reimbursement.content_id] =
        reimbursement.amount;
    }
  });
  return contentTotalReimbursements;
};

/**
 * payの配列から、content_idをキーとした、支払い人数を集計する
 */
const aggregatePayData = (
  data: WaricanPay[]
): { [contentId: string]: number } => {
  const contentPayMembers: { [contentId: string]: number } = {};
  data.forEach((payData) => {
    if (!payData.pay) return;
    if (contentPayMembers[payData.content_id]) {
      contentPayMembers[payData.content_id] += 1;
    } else {
      contentPayMembers[payData.content_id] = 1;
    }
  });
  return contentPayMembers;
};

export const ReimBursementGrid = (props: ReimBursementGridProps) => {
  const contentsData = useGetContentsByEventIdQuery({ eventId: props.eventId });
  const memberData = useGetMembersByEventIdQuery({ eventId: props.eventId });
  const personsToPay = useGetPaysByEventIdQuery({ eventId: props.eventId });
  const { enqueueSnackbar: popUpSnackbar } = useSnackbar();
  const [upsertPay] = useUpsertPayMutation();
  const [rowData, setRowData] = useState<any[]>([]);
  const reimbursementData = useGetReimbursementsQuery({
    eventId: props.eventId,
  });
  const contentsAmount = useMemo(() => {
    return aggregateReimbursementData(reimbursementData.data || []);
  }, [reimbursementData.data]);

  const contentsPayMember = useMemo(() => {
    if (!personsToPay.data) return {};
    return aggregatePayData(personsToPay.data);
  }, [personsToPay.data]);

  // set row data.
  useEffect(() => {
    // data feedが終わっていない場合は何もしない
    if (
      !memberData.data ||
      !personsToPay.data ||
      !contentsData.data ||
      Object.keys(contentsAmount).length === 0
    )
      return;
    const numMember = memberData.data.length;
    console.debug("contents", contentsPayMember);

    // set default value
    const contentsDefault: { [contentId: string]: boolean | number } = {};
    contentsData.data.forEach((content) => {
      contentsDefault[changeToPayBoolField(content.content_id)] = true;
      contentsDefault[content.content_id] =
        contentsAmount[content.content_id] /
        (contentsPayMember[content.content_id] ?? numMember);
    });

    const memberPaymentInfo: {
      [memberId: string]: { [contentId: string]: boolean | string | number } & {
        memberName?: string;
        memberId?: string;
      };
    } = {};
    memberData.data.forEach((member) => {
      memberPaymentInfo[member.member_id] = {
        memberName: member.name,
        memberId: member.member_id,
        ...contentsDefault,
      };
    });

    personsToPay.data.forEach((person) => {
      const fieldToPayBool = changeToPayBoolField(person.content_id);
      if (person.member_id) {
        memberPaymentInfo[person.member_id][fieldToPayBool] = person.pay;
        memberPaymentInfo[person.member_id][person.content_id] = person.pay
          ? contentsAmount[person.content_id] /
            contentsPayMember[person.content_id]
          : 0;
      } else {
        memberPaymentInfo[person.member_id] = {
          [fieldToPayBool]: person.pay,
        };
      }
    });
    console.debug("member", memberPaymentInfo);
    // flatten
    const rowData = Object.keys(memberPaymentInfo).map((memberId) => {
      return memberPaymentInfo[memberId];
    });
    setRowData(rowData);
    console.debug("rowData", rowData);
  }, [
    memberData.data,
    personsToPay.data,
    contentsAmount,
    contentsData.data,
    contentsPayMember,
  ]);

  useEffect(() => {
    if (reimbursementData.data) {
      aggregateReimbursementData(reimbursementData.data);
      console.debug("reimbursementData", reimbursementData.data);
    }
  }, [reimbursementData.data]);

  const columnDefs = useMemo(() => {
    if (contentsData.data) {
      return generateColumnDefs(
        contentsData.data,
        props.eventId,
        upsertPay,
        contentsPayMember,
        contentsAmount,
        popUpSnackbar
      );
    }
  }, [
    contentsAmount,
    contentsData.data,
    contentsPayMember,
    popUpSnackbar,
    props.eventId,
    upsertPay,
  ]);

  return (
    <WaricanGrid
      columnDefs={columnDefs}
      rowData={rowData}
      getRowId={(params) => {
        return params.data.memberId;
      }}
    />
  );
};
