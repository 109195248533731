import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { WaricanAutoComplete } from "src/component/atoms/auto_complete/auto_complete";
import { WaricanButton } from "src/component/atoms/button/button";
import { WaricanDeleteIcon } from "src/component/atoms/icons/delete_icon/delete_icon";
import { WariCanInput } from "src/component/atoms/input/input";
import { WaricanText } from "src/component/atoms/text/text";
import { MemberAutoComplete } from "src/component/organisms/member_auto_complete/member_auto_complete";

export type SettlementFormProps = {
  eventId: string;

  title?: string;
  /**
   * when settlementID is None, this component is new
   */
  settlementID?: string;
};

export const SettlementForm = (props: SettlementFormProps) => {
  const isNew = useMemo(() => {
    return props.settlementID === undefined;
  }, [props.settlementID]);

  const buttonMessage = useMemo(() => {
    return isNew ? "追加" : "修正";
  }, [isNew]);

  const [memberFrom, setMemberFrom] = useState<string>("");
  const [memberTo, setMemberTo] = useState<string>("");
  const [amount, setAmount] = useState<string>("");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isNew ? <></> : <WaricanDeleteIcon />}
      <MemberAutoComplete
        onChange={setMemberFrom}
        placeholder="from"
        eventId={props.eventId}
        title="from"
        defaultMemberId={""}
      />{" "}
      <WaricanText textJp="から" />
      <MemberAutoComplete
        onChange={setMemberFrom}
        placeholder="from"
        eventId={props.eventId}
        title="from"
        defaultMemberId={""}
      />
      <WaricanText textJp="へ" />
      <WariCanInput sx={{ flexGrow: 1 }} value="test"></WariCanInput>
      <WaricanText textJp="円" />
      <WaricanButton message={buttonMessage} />
    </Box>
  );
};
