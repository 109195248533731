import { ReactElement, createContext, useState, useContext } from "react";
import { WaricanModal } from "src/component/atoms/modal/warican_modal";

export type ModalContextType = {
  isOpen: boolean;
  open: (content: ReactElement) => void;
  setIsOpen: (open: boolean) => void;
};

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  open: () => {},
  setIsOpen: () => {},
});

export type ModalContextProps = {
  children: ReactElement;
};
export const ModalWrapper = (props: ModalContextProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement | null>(null);

  return (
    <ModalContext.Provider
      value={{
        isOpen: isOpen,
        open: (content: ReactElement) => {
          setModalContent(content);
          setIsOpen(true);
        },
        setIsOpen: setIsOpen,
      }}
    >
      <WaricanModal
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
      >
        {modalContent ?? <></>}
      </WaricanModal>
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
