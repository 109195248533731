import { useCallback, useMemo } from "react";
import {
  CompleteItem,
  WaricanAutoComplete,
} from "src/component/atoms/auto_complete/auto_complete";
import { useRootSelector } from "src/store/root_store";
import { isSuccessResponse } from "src/store/util";
import {
  useGetContentsByEventIdQuery,
  usePostContentMutation,
} from "src/store/waricanApi";

export type ContentAutoCompleteProps = {
  eventId: string;
  placeholder: string;
  title: string;
  onChange: (contentId: string) => void;
  defaultContentId?: string;
};

/**
 *
 * @param props
 * @returns
 */
export const ContentAutoComplete = (props: ContentAutoCompleteProps) => {
  const user = useRootSelector((state) => state.user.user);

  const contentData = useGetContentsByEventIdQuery({
    eventId: props.eventId ?? "",
  });
  const [postContent] = usePostContentMutation();

  const contentItem = useMemo<CompleteItem[]>(() => {
    if (contentData.data) {
      return contentData.data.map((content) => {
        return { displayName: content.name, id: content.content_id };
      });
    } else {
      return [];
    }
  }, [contentData.data]);

  const createContent = useCallback(
    (newContentName: string) => {
      console.info("createContent", newContentName);
      if (props.eventId && user.userId) {
        postContent({
          eventId: props.eventId,
          body: { name: newContentName, create_user: user.userId },
        })
          .then((resp) => {
            console.log("update", resp);
            if (isSuccessResponse(resp)) {
              props.onChange(resp.data.content_id);
            }
          })
          .catch((e) => {
            console.error("error", e);
          });
      }
    },
    [postContent, props, user.userId]
  );

  const initialContent = useMemo<CompleteItem | undefined>(() => {
    if (props.defaultContentId) {
      const content = contentItem.find(
        (content) => content.id === props.defaultContentId
      );
      if (content) {
        return content;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [contentItem, props.defaultContentId]);
  return (
    <WaricanAutoComplete
      sx={{ flexGrow: 1 }}
      title={props.title}
      placeholder={props.placeholder}
      suggestions={contentItem}
      onCreateNewItem={createContent}
      onChange={props.onChange}
      initialValue={initialContent}
    />
  );
};
