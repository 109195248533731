import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";

export type AccordionProps = {
  /**
   * The text to be displayed as the title of the accordion.
   */
  title: string;

  /**
   * The content to be displayed as the content of the accordion.l
   */
  children: ReactNode;

  /**
   * If true, the accordion will be expanded by default.
   */
  defaultExpanded?: boolean;
};
export const WaricanAccordion = (props: AccordionProps) => {
  return (
    <Accordion
      variant="outlined"
      color="primary"
      defaultExpanded={props.defaultExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} color="primary">
        <Typography color={"primary"}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
