import { createTheme } from "@mui/material";
import { rgba } from "polished";

/**
 * mui theme.
 */
export const myTheme = createTheme({
  palette: {
    primary: {
      light: rgba("#20c9c9", 0.2),
      main: "#0e9292",
    },
    background: {
      default: "#f5f5f5",
    },
    text: {
      primary: "#3b3e41",
      secondary: "#1a3550",
      disabled: "#a0a0a0",
    },
  },

  typography: {
    fontFamily: [
      "ui-sans-serif",
      "system-ui",
      "M PLUS 1p",
      "source-code-pro",
      "Menlo",
      "Monaco",
      "Consolas",
      "Courier New",
      "monospace",
    ].join(","),
  },
});
