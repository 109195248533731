import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WaricanButton } from "src/component/atoms/button/button";
import { Head } from "src/component/atoms/head/head";
import { WariCanInput } from "src/component/atoms/input/input";
import { WaricanText } from "src/component/atoms/text/text";
import { EventViewer } from "src/component/molecules/event_form/event_viewer";
import { Header } from "src/component/organisms/header/header";
import { useRootSelector } from "src/store/root_store";
import { appendEvent } from "src/store/user_defined/user_slice";
import {
  PostEventApiResponse,
  usePostEventMutation,
} from "src/store/waricanApi";

export const HomePage = () => {
  const [postEvent] = usePostEventMutation();
  const navigate = useNavigate();
  const [eventTitle, setEventTitle] = useState<string>("");
  const user = useRootSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const createEvent = useCallback(() => {
    postEvent({ body: { user_id: "aaa", title: eventTitle } })
      .then((res) => {
        console.log(res);
        // TODO ちゃんと type guardを使ってください
        const resSuccess = res as { data: PostEventApiResponse };
        navigate(`/event/${resSuccess.data.event_id}`);
        dispatch(
          appendEvent({
            event_id: resSuccess.data.event_id,
            title: eventTitle,
            created_at: resSuccess.data.created_at,
            updated_at: resSuccess.data.updated_at,
          })
        );
      })
      .catch((err) => {
        console.warn(err);
      });
  }, [postEvent, eventTitle, navigate, dispatch]);

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Head level={3}>Home</Head>
      <Box width={"100%"} display={"flex"} margin={"10px"}>
        <WariCanInput
          value={eventTitle}
          onChange={(e) => {
            setEventTitle(e.target.value);
          }}
        />
        <div style={{ marginLeft: "auto", marginRight: "10px" }}>
          <WaricanButton
            disabled={eventTitle.length === 0}
            message="新しいイベントを作成"
            onClick={createEvent}
          />
        </div>
      </Box>
      <Head level={3}>
        <WaricanText textJp="最近使ったもの" />
      </Head>
      <List style={{ display: "block" }}>
        {user.createEvents?.map((event) => {
          return <EventViewer event={event} />;
        })}
      </List>
      {user.visitEvents?.map((event) => {
        return <EventViewer event={event} />;
      })}
      <Head level={3}>
        <WaricanText textJp="使い方" />
      </Head>
      <HowToUse />
    </div>
  );
};

const steps = [
  {
    label: "イベントを作成",
    description: `画面上部のイベント作成フォームから新規イベントを作成します。`,
  },
  {
    label: "立て替えを記録",
    description: "「誰が」「いくら」立て替えたかを入力します。URLを共有することで他の人も立て替えを記録できます。",
  },
  {
    label: "支払いを調整",
    description: `テーブルをクリックすることで項目ごとに支払いの調整を行うことができます。`,
  },
];

/**
 * @reference https://mui.com/material-ui/react-stepper/
 * @returns
 */
const HowToUse = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <Box width={"100%"}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <WaricanButton
                    // variant="contained"
                    message={index === steps.length - 1 ? "終了" : "次へ"}
                    onClick={handleNext}
                    // sx={{ mt: 1, mr: 1 }}
                  />
                  <WaricanButton
                    disabled={index === 0}
                    onClick={handleBack}
                    message={"前へ"}
                    // sx={{ mt: 1, mr: 1 }}
                  />
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
};
