import { useMediaQuery } from "@mui/material";

export type ResponsiveFrameProps = {
  children: React.ReactNode;
};
export const ResponsiveFrame = (props: ResponsiveFrameProps) => {
  const isPC = useMediaQuery("(min-width:800px)");
  if (isPC) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "800px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%", height: "100%" }}>{props.children}</div>
    );
  }
};
