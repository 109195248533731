import { useCallback, useMemo } from "react";
import {
  CompleteItem,
  WaricanAutoComplete,
} from "src/component/atoms/auto_complete/auto_complete";
import { useRootSelector } from "src/store/root_store";
import { isSuccessResponse } from "src/store/util";
import {
  useGetMembersByEventIdQuery,
  usePostMemberMutation,
} from "src/store/waricanApi";

export type MemberAutoCompleteProps = {
  eventId: string;
  placeholder: string;
  title: string;
  onChange: (memberId: string) => void;
  defaultMemberId?: string;
};
/**
 *
 * @param props
 * @returns
 */
export const MemberAutoComplete = (props: MemberAutoCompleteProps) => {
  const user = useRootSelector((state) => state.user.user);

  const memberData = useGetMembersByEventIdQuery({
    eventId: props.eventId ?? "",
  });
  const [postMember] = usePostMemberMutation();

  const memberItem = useMemo<CompleteItem[]>(() => {
    if (memberData.data) {
      return memberData.data.map((member) => {
        return { displayName: member.name, id: member.member_id };
      });
    } else {
      return [];
    }
  }, [memberData.data]);

  const createMember = useCallback(
    (newMemberName: string) => {
      console.info("createMember", newMemberName);
      if (props.eventId && user.userId) {
        postMember({
          eventId: props.eventId,
          body: { name: newMemberName, create_user: user.userId },
        })
          .then((resp) => {
            console.log("update", resp);
            if (isSuccessResponse(resp)) {
              props.onChange(resp.data.member_id);
            }
          })
          .catch((e) => {
            console.error("error", e);
          });
      }
    },
    [postMember, props, user.userId]
  );

  const initialMember = useMemo<CompleteItem | undefined>(() => {
    if (props.defaultMemberId) {
      const content = memberItem.find(
        (content) => content.id === props.defaultMemberId
      );
      if (content) {
        return content;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [memberItem, props.defaultMemberId]);
  //   console.debug("initialMember", initialMember);
  return (
    <WaricanAutoComplete
      sx={{ flexGrow: 1 }}
      title={props.title}
      placeholder={props.placeholder}
      suggestions={memberItem}
      onCreateNewItem={createMember}
      onChange={props.onChange}
      initialValue={initialMember}
    />
  );
};
