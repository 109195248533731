import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import { WaricanIconProps } from "src/component/atoms/icons/icon_core";

/**
 * DeleteIcon.
 * @returns DeleteIcon.
 * @example
 * <DeleteIcon />
 * @todo
 * - [ ] DeleteIcon.
 */
export const WaricanDeleteIcon = (props: WaricanIconProps) => {
  return (
    <DeleteForeverTwoToneIcon
      color={props.color}
      cursor="pointer"
      onClick={props.onClick}
    />
  );
};
