import { Head } from "src/component/atoms/head/head";
import { Header } from "src/component/organisms/header/header";

export const TermOfServicePage = () => {
  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Head level={1}>利用規約</Head>
      <Head level={3}>適用</Head>
      本規約は、ユーザーと本サービスとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
      <Head level={3}>禁止事項</Head>
      <p>
        ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        法令または公序良俗に違反する行為 犯罪行為に関連する行為
        本サービスのサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
        本サービスのサービスの運営を妨害するおそれのある行為
        他のユーザーに関する個人情報等を収集または蓄積する行為
        他のユーザーに成りすます行為
        本サービスのサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
        その他、本サービスが不適切と判断する行為
      </p>
      <Head level={3}>本サービスの提供の停止等</Head>
      本サービスは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
      本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
      地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
      コンピュータまたは通信回線等が事故により停止した場合
      その他、本サービスが本サービスの提供が困難と判断した場合
      本サービスは、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
      <Head level={3}>利用制限</Head>
      本サービスは、以下の場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
      本規約のいずれかの条項に違反した場合
      その他、本サービスが本サービスの利用を適当でないと判断した場合
      本サービスは、本条に基づき本サービスが行った行為によりユーザーに生じた損害について、一切の責任を負いません。
      <Head level={3}>サービス内容の変更等</Head>
      本サービスは、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
      <Head level={3}>利用規約の変更</Head>
      本サービスは、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
      <Head level={3}>権利義務の譲渡の禁止</Head>
      ユーザーは、本サービスの書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
      <Head level={3}>免責</Head>
      当社は、次の各号の場合において、一切その責任を負わないものとします。
      本サイトを介して行う、第三者が提供するコンテンツのダウンロード、および、第三者が管理・運営するリンクサイトへのアクセス等の行為により、利用者に生じた損害。
      当社が合理的な安全策を講じたにもかかわらず、本サイトの無断改変、本サイトに関するデータへの不正アクセス、コンピュータウィルスの混入等の不正行為が行われ、これに起因して利用者に生じた損害。
      その他本サイトの利用（これらに伴う当社による情報提供行為等を含みます）若しくは不利用により生じる一切の損害（精神的苦痛、またはその他の金銭的損失を含む一切の不利益）。
      前項各号において、万一当社が責任を負う場合であっても、当社が責任を負うのは当社に故意または重過失がある場合に限るものとし、またその責任範囲は直接かつ通常の損害に限られるものとします。
      準拠法・裁判管轄
      本規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合には、本サービスの本店所在地を管轄する裁判所を専属的合意管轄とします。
    </div>
  );
};
