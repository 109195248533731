export type TextProps = {
  /**
   * The text to be displayed in Japanese.
   */
  textJp?: string;
  /**
   * The text to be displayed in Japanese.
   */
  textEn?: string;
};

/**
 * A component that displays text in any language.
 */
export const WaricanText = (props: TextProps) => {
  return <span style={{ padding: "2px" }}>{props.textJp}</span>;
};
