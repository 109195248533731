import { Input, SxProps } from "@mui/material";
import { useCallback } from "react";

export type WaricanInputProps = {
  value: string | number;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  sx?: SxProps;
  type?: string;
  placeholder?: string;
};

export const WariCanInput = (props: WaricanInputProps) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
    },
    [props]
  );
  return (
    <Input
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      onChange={onChange}
      sx={props.sx}
    />
  );
};
