import { Moment } from "moment";

export type WaricanDateProps = {
  date: Moment;
};
/**
 * Data viewer for warican.
 * @param props
 * @returns
 */
export const WaricanDate = (props: WaricanDateProps) => {
  return <div>{props.date.format("YYYY-MM-DD")}</div>;
};
