import { Route, Routes } from "react-router-dom";
import { HomePage } from "src/component/pages/home/home_page";
import { EventPage } from "src/component/pages/event/event_page";
import { TermOfServicePage } from "src/component/pages/term_of_service/term_of_service_page";
import { NotFoundPage } from "src/component/pages/not_found/not_found_page";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} /> {/*RouteにHomeを設定する*/}
      <Route path="/event/:id" element={<EventPage />} />
      <Route path="/term_of_service" element={<TermOfServicePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
