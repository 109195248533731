import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Head } from "src/component/atoms/head/head";
import { WaricanShareIcon } from "src/component/atoms/icons/share_icon/share_icon";
import { WaricanAccordion } from "src/component/molecules/accordion/accordion";
import { Header } from "src/component/organisms/header/header";
import { ReimbursementForm } from "src/component/organisms/reimbursement_form/reimbursement_form";
import { ReimBursementGrid } from "src/component/organisms/reimbursement_grid/reimbursement_grid";
import { SettlementForm } from "src/component/organisms/settlement_form/settlement_form";
import { WaricanReimbursement } from "src/model/reimbursement";
import { visitEvent } from "src/store/user_defined/user_slice";
import {
  useGetEventQuery,
  useGetReimbursementsQuery,
} from "src/store/waricanApi";
import { defaultSnackbarOptions } from "src/util/notification";

type OptionalReimbursement = Partial<WaricanReimbursement>;

export const EventPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { enqueueSnackbar: popUpSnackbar } = useSnackbar();
  const reimbursementsData = useGetReimbursementsQuery(
    { eventId: id ?? "" },
    { skip: !id }
  );

  const localReimbursementData = useMemo<OptionalReimbursement[]>(() => {
    if (reimbursementsData.data) {
      return [...reimbursementsData.data, { reimbursement_id: undefined }];
    } else {
      return [
        {
          reimbursement_id: undefined,
        },
      ];
    }
  }, [reimbursementsData.data]);

  const eventData = useGetEventQuery({ eventId: id ?? "" }, { skip: !id });

  useEffect(() => {
    if (eventData.data) {
      dispatch(
        visitEvent({
          event_id: eventData.data.event_id,
          title: eventData.data.title,
          created_at: eventData.data.created_at,
          updated_at: eventData.data.updated_at,
        })
      );
    }
  }, [dispatch, eventData.data]);

  const onClickURLCopyButton = useCallback(() => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    popUpSnackbar("URLをコピーしました。", {
      ...defaultSnackbarOptions,
      variant: "info",
    });
  }, [popUpSnackbar]);

  if (id) {
    return (
      <div style={{ width: "100%" }}>
        <Header />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Head level={1} style={{ width: "95%" }}>
            <Box style={{ display: "flex", width: "100%" }}>
              <div>{eventData.data?.title}</div>
              <div
                style={{
                  marginLeft: "auto",
                }}
              >
                <WaricanShareIcon
                  color="primary"
                  onClick={onClickURLCopyButton}
                />
              </div>
            </Box>
          </Head>
        </div>
        <WaricanAccordion title="サマリー" defaultExpanded={true}>
          <ReimBursementGrid eventId={id} />
        </WaricanAccordion>

        <WaricanAccordion title="立て替え">
          {localReimbursementData.map((reimbursement) => {
            return (
              <ReimbursementForm
                // undefinedにすることで更新を強制し、新規立て替え追加時のformの初期化を行っています。
                key={reimbursement.reimbursement_id ?? undefined}
                eventId={id}
                reimbursementID={reimbursement.reimbursement_id}
                amount={reimbursement.amount}
                content_id={reimbursement.content_id}
                reimbursement_member={reimbursement.reimbursement_member}
                currency={reimbursement.currency}
              />
            );
          })}
        </WaricanAccordion>
        <WaricanAccordion title="設定">
          <SettlementForm eventId={id} />
        </WaricanAccordion>
      </div>
    );
  } else {
    return <div>error id is undefined.</div>;
  }
};
