import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { ReactElement } from "react";
import { Box, Fade } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export type WaricanModalProps = {
  open: boolean;
  handleClose: () => void;
  children: ReactElement;
};

export const WaricanModal = (props: WaricanModalProps) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.open}>
        <Box sx={style}>{props.children}</Box>
      </Fade>
    </Modal>
  );
};
