import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { WaricanReimbursement } from "src/model/reimbursement";

export type WaricanGridProps = {
  style?: React.CSSProperties;
} & AgGridReactProps;

export const convertToAgGridData = (data: WaricanReimbursement[]) => {
  console.debug("convert", data);
};

export const WaricanGrid = ({ style, ...agGridProps }: WaricanGridProps) => {
  return (
    <div className="ag-theme-alpine" style={style}>
      <AgGridReact
        domLayout="autoHeight"
        {...agGridProps}
        defaultColDef={{ resizable: true, suppressMovable: true }}
      />
    </div>
  );
};
