import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { rootApi } from "src/store/root_api";
import { gridSlice } from "src/store/user_defined/grid";
import { userSlice } from "src/store/user_defined/user_slice";

export const rootStore = configureStore({
  reducer: {
    // [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [rootApi.reducerPath]: rootApi.reducer,
    [userSlice.name]: userSlice.reducer,
    [gridSlice.name]: gridSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware),
});

export type AppDispatch = typeof rootStore.dispatch;
export type RootState = ReturnType<typeof rootStore.getState>;
export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
