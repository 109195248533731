import CloseIcon from "@mui/icons-material/Close";
import { SnackbarAction, closeSnackbar } from "notistack";

/**
 * @example
 * const { enqueueSnackbar: popUpSnackbar } = useSnackbar();
 * const onClickURLCopyButton = useCallback(() => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    popUpSnackbar("URLをコピーしました。", {
      autoHideDuration: 2000,
      preventDuplicate: true,
      variant: "info",
      // ! please use as below.
      action: action,
    });
  }, [popUpSnackbar]);
 * @param snackbarId 
 * @returns 
 */
export const closeSnackbarAction: SnackbarAction = (snackbarId) => (
  <>
    <CloseIcon
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    />
  </>
);

export const defaultSnackbarOptions = {
  autoHideDuration: 4000,
  preventDuplicate: true,
  action: closeSnackbarAction,
};
