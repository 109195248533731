import { Box, ListItem, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { WaricanDate } from "src/component/atoms/date/date";
import { WaricanText } from "src/component/atoms/text/text";
import { createEventPath } from "src/core/route";
import { WaricanEvent } from "src/model/event";

export type EventViewerProps = {
  event: WaricanEvent;
};

export const EventViewer = (props: EventViewerProps) => {
  const navigate = useNavigate();
  return (
    <ListItem disablePadding style={{ width: "100%", display: "block" }}>
      <ListItemButton
        onClick={() => navigate(createEventPath(props.event.event_id))}
        style={{ width: "100%", display: "flex", alignItems: "center" }}
      >
        <ListItemText primary={props.event.title} />
        <div style={{ marginLeft: "auto", marginRight: "0px" }}>
          <WaricanDate date={moment(props.event.created_at)} />
        </div>
      </ListItemButton>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}></Box>
    </ListItem>
  );
};
