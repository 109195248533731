import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

type ValueType<ResponseType> =
  | {
      data: ResponseType;
    }
  | {
      error: FetchBaseQueryError | SerializedError;
    };

/**
 * util function for rtk-query return type guard
 * @param result
 * @returns
 */
export const isSuccessResponse = <ResponseType>(
  result: ValueType<ResponseType>
): result is { data: ResponseType } => {
  if ("error" in result) return false;
  return true;
};
