/**
 * user util functions
 */

import { generateUUID4 } from "src/core/uuid";
import { User } from "src/model/user";
import { getCookie, saveCookie } from "src/storage/cookie";

/**
 * save user data to cookie.
 * this user data save  two years.
 */
export const saveUserData = (user: User) => {
  saveCookie("user", JSON.stringify(user), 730);
};

export const prepareUserData = (): User => {
  const user = loadUserData();
  if (user) {
    return user;
  } else {
    const newUser = createUserData();
    saveUserData(newUser);
    return newUser;
  }
};

/**
 * get user data from cookie.
 */
const loadUserData = (): User | undefined => {
  const user = getCookie("user");
  if (user) {
    return JSON.parse(user);
  } else {
    return undefined;
  }
};

const createUserData = (): User => {
  console.info("createUserData");
  const user: User = {
    userId: generateUUID4(),
    userName: "これなんに使うんですかね",
    createEvents: [],
    visitEvents: [],
  };

  return user;
};
