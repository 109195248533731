import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WaricanEvent } from "src/model/event";
import { User } from "src/model/user";
import { prepareUserData, saveUserData } from "src/util/user";

type UserState = {
  user: User;
};

const initialState: UserState = {
  user: prepareUserData(),
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    // setUser: (state, action: PayloadAction<User>) => {
    //   state.user = action.payload;
    // },
    appendEvent: (state, action: PayloadAction<WaricanEvent>) => {
      state.user.createEvents?.push(action.payload);
      saveUserData({ ...state.user });
    },
    visitEvent: (state, action: PayloadAction<WaricanEvent>) => {
      state.user.visitEvents?.push(action.payload);
      saveUserData({ ...state.user });
    },
  },
});

export const { appendEvent, visitEvent } = userSlice.actions;
